import { api } from "@leat/lib";
export class LeatApiError extends Error {
    status;
    statusText;
    data;
    message;
    constructor(status, statusText, data) {
        super(`LeatApiError: ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
        this.message = data;
    }
}
export class LeatFrontendService {
    async getShops() {
        const { data, error } = await api.get("/leat/private/shops");
        if (error ?? !data) {
            if (error) {
                throw new LeatApiError(error.status, error.statusText, error.data);
            }
            throw new Error("No data returned");
        }
        return data;
    }
    async getRewards() {
        const { data, error } = await api.get("/leat/private/rewards");
        if (error ?? !data) {
            if (error) {
                throw new LeatApiError(error.status, error.statusText, error.data);
            }
            throw new Error("No data returned");
        }
        return data;
    }
    async getCoupons(userId) {
        const endpoint = "/leat/v1/coupons";
        const url = userId !== null ? `${endpoint}?userId=${userId}` : endpoint;
        const { data, error } = await api.get(url);
        if (error) {
            throw new LeatApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async getContact(userId) {
        const endpoint = "/leat/v1/contact";
        const url = userId !== null ? `${endpoint}?userId=${userId}` : endpoint;
        const { data, error } = await api.get(url);
        if (error) {
            throw new LeatApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async claimReward(earnRuleId, userId) {
        const { data, error } = await api.post("/leat/v1/earn-reward", {
            userId,
            earnRuleId,
        });
        if (error) {
            if (error) {
                throw new LeatApiError(error.status, error.statusText, error.data);
            }
            throw new Error("No data returned");
        }
        return data;
    }
    async getEarnRules() {
        const { data, error } = await api.get("/leat/v1/earn-rules");
        if (error) {
            throw new LeatApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async getSpendRules(userId) {
        const endpoint = "/leat/v1/spend-rules";
        const url = userId !== null ? `${endpoint}?userId=${userId}` : endpoint;
        const { data, error } = await api.get(url);
        if (error) {
            throw new LeatApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async joinProgram(userId) {
        const { data, error } = await api.post("/leat/v1/join-program?g", {
            userId,
        });
        if (error) {
            throw new LeatApiError(error.status, error.statusText, error.data);
        }
        return data;
    }
    async claimSpendRule(spendRuleId, userId) {
        const { data, error } = await api.post(`/leat/v1/spend-rules-claim`, {
            userId,
            id: spendRuleId,
        });
        if (error) {
            if (error) {
                throw new LeatApiError(error.status, error.statusText, error.data);
            }
            throw new Error("No data returned");
        }
        return data;
    }
}
export const apiService = new LeatFrontendService();
